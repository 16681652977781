import { getCookie, log, setCookie } from "../../helpers";
import UserModel from "../../models/User/userModel";

class UserController extends UserModel {
  constructor() {
    super();
    this.tagKey = "amzTag";
  }
  saveTagCookie(tag) {
    setCookie(this.tagKey, tag, 365, true, "None");
  }
  getFingerPrint() {
    return new Promise((res, rej) => {
      const cookieTag = getCookie(this.tagKey);
      const tag = cookieTag || localStorage.getItem(this.tagKey);
      log(tag, "Finger Cookie is:");
      if (!tag) {
        this.requestFingerPrint()
          .then((res) => {
            if (res.status === 200) {
              return res.text();
            } else {
              throw new Error(res.status);
            }
          })
          .then((key) => {
            localStorage.setItem(this.tagKey, key.toString());
            res(key);
          })
          .catch((err) => {
            log(err, "UserController Fetch Error:");
            rej(err);
          });
      } else {
        !cookieTag && this.saveTagCookie(tag);
        res(tag);
      }
    });
  }
}

export default UserController;
