const TARIFFS = {
  monthly: {
    name: "Monthly",
    pay: {
      price: 35,
      cents: 99,
      period: "month",
    },
    buyNow: {
      path: "/checkout/?offerId=addon_new_monthly",
    },
  },
  annual: {
    name: "Annual",
    pay: {
      discount: 431.88,
      price: 199,
      cents: 99,
      period: "year",
    },
    buyNow: {
      path: "/checkout/?offerId=addon_new_annual",
    },
  },
  lifetime: {
    name: "Lifetime",
    pay: {
      discount: 1799,
      price: 999,
      cents: 99,
      period: "lifetime",
    },
    buyNow: {
      path: "/checkout/?offerId=addon_new_lifetime",
    },
  },
};
export default TARIFFS;
