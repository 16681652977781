class UserModel {
  constructor() {
    this.requestHeaders = {
      Accept: "application/json",
      "Content-type": "application/json; charset=utf-8",
    };
  }

  requestFingerPrint() {
    return fetch(process.env.AUTH_HOST + "/v1/auth/tag", {
      headers: this.requestHeaders,
      method: "GET",
    });
  }
}

export default UserModel;
