import { useState, useEffect } from "react";
import UserController from "../controllers/User/userController";
import { log } from "../helpers";

const useFingerPrint = (enablePageView) => {
  const [getFingerTimer, setFingerTimer] = useState(0);

  useEffect(() => {
    setFingerTimer(window.setTimeout(handlePageViewWithFingerPrint, 500));
    return () => {
      clearTimeout(getFingerTimer);
    };
  }, []);

  const sendPageViewEvent = () => {
    if (typeof window !== "undefined" && window.gtag) {
      enablePageView && window.gtag("event", "page_view");
    }
  };

  const handlePageViewWithFingerPrint = () => {
    new UserController()
      .getFingerPrint()
      .then((tag) => {
        log(tag, "USER ID:");
        window.gtag("set", { user_id: tag });
      })
      .catch((err) => {
        log(err);
      })
      .finally(() => {
        sendPageViewEvent();
      });
  };
};

export default useFingerPrint;
