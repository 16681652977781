const queue = []
let sync = null

const sa = function (category, action, label, value, software) {
  if (typeof window !== "undefined") {
    software = software || new URL(window.location.href).searchParams.get("software") || "LANDING"

    function getUTM() {
      const url = window.location.href
      const idx = url.indexOf("?")
      const query = idx > 0 ? url.substr(idx + 1) : null
      let params = null
      if (query) {
        params = {}
        query
          .split("&")
          .filter(function (p) {
            return p.startsWith("utm_")
          })
          .forEach(function (p) {
            const matches = p.match(/utm_(.*)=(.*)/)
            params[matches[1]] = decodeURIComponent(matches[2])
          })
      }
      return params
    }

    function serialize(obj) {
      if (!obj) {
        return ""
      } else if (typeof obj == "string") {
        return encodeURIComponent(obj)
      } else if (typeof obj == "object") {
        let s = ""
        for (const k in obj) {
          if (obj.hasOwnProperty(k) && obj[k] !== undefined) {
            if (s) {
              s += "&"
            }
            s += k + "=" + encodeURIComponent(obj[k])
          }
        }
        return s
      } else {
        throw "Unsupported object type"
      }
    }

    function merge(o1, o2) {
      if (!o1) {
        return o2
      }
      if (!o2) {
        return o1
      }
      if (Object.assign) {
        return Object.assign(o1, o2)
      }
      Object.getOwnPropertyNames(o2).forEach(n => (o1[n] = o2[n]))
      return o1
    }

    function sendEvent(e) {
      let utm = {}
      if (e.category === "Pageview") {
        utm = getUTM()
      }

      return new Promise((resolve, reject) => {
        try {
          const xhr = new XMLHttpRequest()
          xhr.withCredentials = true
          xhr.open("POST", `${process.env.HOST}/analytics/v1/events`, true)
          xhr.setRequestHeader(
            "Content-Type",
            "application/x-www-form-urlencoded"
          )

          xhr.onload = resolve
          xhr.onerror = reject

          xhr.send(serialize(merge(e, utm)))
        } catch (e) {
          reject(e)
        }
      })
    }

    function send() {
      if (sync) {
        return sync
      }
      const e = queue.pop()
      if (!e) {
        return
      }

      return (sync = sendEvent(e)
        .finally(() => (sync = null))
        .then(
          () => send(),
          () => queue.push(e)
        )
        .catch(() => queue.push(e)))
    }

    queue.push({
      category: category,
      action: action,
      label: label,
      value: value,
      software: software,
    })

    return send()
  }
}
//Old mode

function checkPageViewExceptions() {
  let result = false
  ;["checkout", "upgrade", "pricing"].forEach(e => {
    if (document.location.pathname.includes(e)) {
      result = true
    }
  })
  return result
}

async function track(category, action, label, value, software, cb, err) {
  if (typeof window !== "undefined") {
    if (category.toLowerCase() !== "pageview") {
      return sa(category, action, label, value, software).then()
    } else if (checkPageViewExceptions()) {
      await sa(
        "view",
        document.location.pathname,
        document.referrer.split("?")[0]
      )
    }
  }
}

export default track
