import React from "react";
import { formatNumber, getTrueClasses } from "../../../../helpers";
import TrackedLink from "../../../Analytics/TrackedLink";
import "./index.scss";

const Tariff = (props) => {
  const {
    name,
    pay: { discount, price, cents, period },
    buyNow: { path },
    software,
    checkoutLanguage,
    paramsLink,
  } = props;
  const eventCategory = `${software ? software : "LANDING"}|Pricing`;
  const updateLink = (link) => {
    paramsLink && (link += paramsLink);
    return checkoutLanguage ? `/${checkoutLanguage}${link}` : link;
  };
  return (
    <div className={getTrueClasses("PgIWOAP-T", `PgIWOAP-T_${name}`)}>
      <div className="PgIWOAP-T__name">{name}</div>
      <div className="PgIWOAP-T-block">
        <p className="PgIWOAP-T__title">
          Add-on on Amazon Product Pages - Dropshipping, Arbitrage, and
          Wholesale Extension
        </p>
        <p className="PgIWOAP-T__subTitle">
          Avoid selling risky products and maximize your success rate by selling
          branded and other existing products
        </p>
        <TrackedLink
          classes="PgIWOAP-T__learnMore"
          category={eventCategory}
          action="Learn more"
          path="/online-arbitrage"
          target
        >
          Learn more
        </TrackedLink>
        <div className="PgIWOAP-T__line" />
        <p className="PgIWOAP-T-pay">
          {discount && (
            <strike className="PgIWOAP-T-pay__discount">
              {`$${formatNumber("en-US", discount)}`}
            </strike>
          )}
          {`$${price.toLocaleString("en-US")}`}
          {cents && <sup className="PgIWOAP-T-pay__cents">{cents}</sup>}
          <span className="PgIWOAP-T-pay__period">/{period}</span>
        </p>
        <TrackedLink
          class="PgIWOAP-T__buyNow"
          category={eventCategory}
          action="Buy now"
          path={updateLink(path)}
          isChild
        >
          Buy now
        </TrackedLink>
        <p className="PgIWOAP-T__refund">10-day money-back guarantee</p>
      </div>
    </div>
  );
};

export default Tariff;
